<script setup lang="ts">
import { defineProps, withDefaults, computed, ref } from "vue";
import { type AppProgress } from "o365.pwa.modules.client.steps.AppProgress.ts";
import AppProgressOffline from "o365.pwa.vue.components.steps.AppProgressOffline.vue";
import AppProgressOnline from "o365.pwa.vue.components.steps.AppProgressOnline.vue";


interface IProps {
  syncStepProgress: AppProgress;
  currentStep: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  currentStep: false,
});

</script>

<script lang="ts">
export default {
  name: "AppProgress"
};
</script>

<template>
  <AppProgressOffline v-if="props.syncStepProgress.syncType === 'OFFLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep" />
  <AppProgressOnline v-else-if="props.syncStepProgress.syncType === 'ONLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep" />
</template>


<style scoped>
.small-subtitle {
  font-size: 12px;
}
</style>